.blue {
  color: var(--color-prussian-blue);
}

.gray {
  color: var(--color-cement);
}

.highlight {
  font-size: 14px;
  font-weight: bold;
}

.subtitle {
  font-size: 12px;
}

.highlightRow {
  background: #f3f4f6;
  vertical-align: top;
}
.row {
  vertical-align: top;
}
