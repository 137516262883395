.container {
  width: 100%;
}

.dropdown {
  width: 100%;
  height: 38px;

  :global {
    .MuiSelect-root.MuiSelect-select:focus {
      background: none;
    }

    fieldset {
      border-color: rgba(0, 0, 0, 0.082);
    }
  }
}
