.button {
  width: 89px;
  background: var(--color-light-gold);
  transition: background 0.5s;

  .text {
    color: var(--color-prussian-blue);
    font-weight: 500;
    align-items: center;
    display: flex;
  }

  .icon {
    font-size: 15px;
    margin-right: 10px;
  }

  &:hover,
  &.active {
    background: var(--color-light-gold);
    .text {
      color: var(--color-prussian-blue);

      > .count {
        color: var(--color-white);
        background: var(--color-prussian-blue);
      }
    }
    .icon {
      font-size: 15px;
      margin-right: 10px;
      color: var(--color-prussian-blue);
    }
  }
}

.title {
  margin-top: 20px;
  margin-left: 0;
  margin-bottom: 10px;
}

.filterContent {
  position: absolute;
  margin-top: 10px;
  width: 443px;
  background: var(--color-white);
  box-shadow: 0 5px 5px 5px rgb(0 0 0 / 2%);
  border-radius: 5px;
  border: 1px solid var(--color-bright-gray);
  z-index: 2;
  padding: 0px 10px;
  &.inActive {
    display: none;
  }
}

.action {
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .clearAll {
    color: var(--color-prussian-blue);
    border: none;
  }
}

.addPad {
  width: 110px !important;
}

.overlay {
  opacity: 0.2;
  position: absolute;
  top: 0px;
  height: 100%;
  left: 0px;
  width: 100%;
  z-index: 1;
}

.count {
  height: 20px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  margin-left: 6px;
  background-color: var(--color-prussian-blue);
  color: white;
  font-size: x-small;
  transition: background 0.5s;
}

.filterIcon {
  min-width: 30px;
  display: flex;
  align-items: center;
  span {
    font-size: 24px !important;
    color: inherit;
  }
}
