.filter {
  display: flex;
  align-items: center;
}

.search {
  width: 40%;
  margin-left: 10px;
}

.fieldContent {
  width: 260px;
}

.fieldControl {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.fieldSelectAll {
  background-color: var(--color-light-grey);
  label {
    font-weight: bold;
    color: var(--color-cement);
  }
}

.content {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--color-bright-gray);
}

.filterInput {
  padding-right: 20px;
  padding-left: 20px;
}
