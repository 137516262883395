.inputFromTo-to .DayPickerInput-Overlay {	
  margin-left: -198px;	
}	

.inputFromTo {	
  display: flex;	
  align-items: center;	
  border-color: var(--color-ash);	
  height: 38px;	
  border-radius: 4px;	
  border-width: 1px;	
  border-style: solid;	
  padding: 10px;	
  cursor: pointer;	

  :global {	
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {	
      // background-color: #f0f8ff !important;	
      background-color: var(--color-blue) !important;	
      color: var(--color-purple);	
    }	
    .DayPicker-Day {	
      border-radius: 0 !important;	
    }	
    .DayPicker-Day--start {	
      background-color: var(--color-purple) !important;	
      border-radius: 50% !important;	
    }	
    .DayPicker-Day.DayPicker-Day--today {	
      color: #000000;	
    }	

    .DayPickerInput-Overlay {	
      margin-left: -10px;	
      margin-top: 7px;	
      border-radius: 10px;	
    }	
    .DayPickerInput > input {	
      border: unset;	
      background-color: inherit;	
      cursor: pointer;	
      &::placeholder {	
        color: #9f9f9f;	
        font-weight: 500 !important;	
        font-family: Roboto;	
      }	
    }	
    .DayPickerInput > input:focus {	
      outline: 0;	
    }	
  }	
}	

.startDate {	
  width: 35%;	
  :global {	
    .DayPickerInput > input {	
      border-right: none;	
      border-top-right-radius: 0;	
      border-bottom-right-radius: 0;	
    }	
  }	
}	

.to {	
  width: 10%;	
  color: #9f9f9f;	
  font-family: Roboto;	
  font-size: 14px;	
  font-weight: 500;	
}	

.endDate {	
  width: 45%;	
  :global {	
    .DayPickerInput > input {	
      border-left: none;	
      border-top-left-radius: 0;	
      border-bottom-left-radius: 0;	
    }	
  }	
}	

.icon {	
  object-fit: unset;	
  height: 20px;	
  width: 20px;	
}