.container {
  background: linear-gradient(to right, #fadf4a -9%, #fadf4a);

  height: 50px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}

.text {
  color: var(--color-prussian-blue);
  margin: auto;
}
