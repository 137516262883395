.fieldContent {
  width: calc(100% - 100px);
}

.container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .content {
    display: flex;
    align-items: center;
  }
}

.search {
  width: 500px;
}
