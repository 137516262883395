.noPage {
  flex-direction: column;
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  flex: 1;
}
