.input {
  width: 100%;

  :global {
    .MuiOutlinedInput-root.MuiInputBase-formControl {
      height: 38px;
    }
    input {
      font-weight: 500;
      font-size: 14px;
    }
    fieldset {
      border-color: var(--color-ash);
    }
    .MuiInputLabel-root {
      opacity: 0 !important;
    }
    .PrivateNotchedOutline-legendLabelled-3 {
      display: none !important;
    }
  }
}
