.filter {
  display: flex;
  align-items: center;
}

.search {
  width: 40%;
  margin-left: 10px;
}

.fieldContent {
  width: 250px;
}

.fieldControl {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.fieldSelectAll {
  background-color: var(--color-light-grey);
  label {
    font-weight: bold;
    color: var(--color-cement);
  }
}

.button {
  background: #fadf4a;
  transition: background 0.5s;
  height: 38px;
  padding: 0 auto;
  .text {
    color: var(--color-prussian-blue);
    font-weight: 500;
    align-items: center;
    display: flex;
  }

  .icon {
    font-size: 15px;
    margin-right: 10px;
  }

  &:hover {
    background: var(--color-light-yellow);
    color: var(--color-prussian-blue);
  }
  &.active {
    background: var(--color-prussian-blue);
    .text {
      color: #ffffff;

      > .count {
        color: var(--color-purple);
        background: #eee0ff;
      }
    }
    .icon {
      font-size: 15px;
      margin-right: 10px;
      color: #ffffff;
    }
  }
}

.filterIcon {
  min-width: 36px;
  display: flex;
  align-items: center;
  span {
    font-size: 24px !important;
    color: inherit;
  }
}

.downloadDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
