.container-to .DayPickerInput-Overlay {
    margin-left: -198px;
  }
  
  .container {
    display: flex;
    align-items: center;
    border-color: var(--color-ash);
    height: 38px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    padding: 10px;
    width: 100%;
  
    &.disabled {
      background: var(--color-concrete);
      border: none;
      cursor: not-allowed;
    }
  
    :global {
      .DayPicker-NavButton--interactionDisabled {
        display: none;
      }
      .DayPicker--interactionDisabled,
      .DayPicker--interactionDisabled .DayPicker-Day {
        cursor: not-allowed !important;
        color: #bbb !important;
      }
      .DayPicker-Day--disabled {
        color: #bbb !important;
        cursor: not-allowed !important;
      }
  
      .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        // background-color: #f0f8ff !important;
        background-color: var(--color-baby-blue) !important;
        color: var(--color-purple);
      }
      .DayPicker-Day {
        border-radius: 0 !important;
        color: var(--color-night);
      }
      .DayPicker-Weekdays,
      .DayPicker-WeekdaysRow,
      .DayPicker-Weekday abbr[title],
      .DayPicker-Month {
        color: var(--color-purple);
      }
  
      .DayPicker-Day--start {
        background-color: var(--color-purple) !important;
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
      }
      .DayPicker-Day.DayPicker-Day--today {
        color: #000000;
      }
      .DayPicker-Day--end {
        background-color: var(--color-purple) !important;
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
      }
      .DayPickerInput-Overlay {
        // width: 600px;
        margin-left: -10px;
        margin-top: 7px;
        border-radius: 10px;
      }
      .DayPickerInput > input {
        border: unset;
        background-color: inherit;
        cursor: pointer;
        &::placeholder {
          color: #9f9f9f;
          font-weight: 500 !important;
          font-family: Roboto;
        }
      }
      .DayPickerInput > input:focus {
        outline: 0;
      }
      .DayPickerInput > input:disabled {
        cursor: auto;
      }
    }
  }
  
  .datePicker {
    display: flex;
    justify-content: space-between;
    width: 100%;
    :global {
      .DayPickerInput > input {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  
  .icon {
    object-fit: unset;
    height: 20px;
    width: 20px;
  }
  