.radioContainer {
  display: flex;
  // align-items: center;
  margin-left: -10px !important;
  &.vertical {
    flex-direction: column;
  }
}

// .radio {
//   // display: flex;
//   // align-items: center;
// }

.disabled {
  filter: grayscale(90%);
  cursor: not-allowed;
}
