.filters {
  margin-top: 37px;
}

.table {
  margin-top: 30px;
}

.city {
  text-transform: capitalize;
}
.batchId {
  text-transform: uppercase;
}
.depot {
  text-transform: capitalize;
}
