.table {
  width: 400px;
  td {
    padding: 10px 0px;
  }
  .label {
    padding-right: 30px;
  }
}

.fieldLabelValue {
  margin-left: 40px;
}

.fieldInput {
  width:100%;
  margin-left: 10px;
}

.reportContainer {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.switchBackground {
  background-color: var(--color-sky-2) !important;
} 

.fieldLabel {
  justify-content: flex-start;
  align-items: flex-start;
}

.fieldContainer {
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
}

.multipleSettlementCollection {
  display: flex;
  margin: 20px;
}

.fieldModalLabel {
  width: 160px;
  text-align: left;
}

.fieldModalHeading {
  width: 180px;
  text-align: left;
  color: var(--color-cement);
}

.modalContent {
  display: flex;
}

.fieldLabelInfo {
  width: 280px;
}

.fieldLabelRemarks {
  width: 55px;
  margin-right: 20px;
}

.fieldLabelProductBreakdown {
  width: 75px;
}

.detailsContainer {
  padding-left: 10px;
  .fieldLabelInfo {
    margin-right: 0px;
  }
}
