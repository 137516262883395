.product {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.circle {
  font-size: 16px;
  margin-right: 8px;
}

.grass {
  color: var(--color-grass);
}

.cheddar {
  color: var(--color-cheddar);
}

.salsa {
  color: var(--color-salsa);
}

.deepBlue {
  color: var(--color-deep-blue);
}

.horizontal {
  display: flex;
  > div:not(:last-child) {
    margin-right: 20px;
  }
}
