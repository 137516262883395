.container {
  width: 290px !important;
  :global {
    .MuiPaper-root {
      width: 290px !important;
      min-width: 290px !important;
    }
  }
}

.arrow {
  background: #fff5e2 !important;
  border-right: 1px solid #e4e5ec !important;
  border-top: 1px solid #e4e5ec !important;
}
.content {
  border: 1px solid #e4e5ec !important;
}

.yellow {
  background: #fff5e2 !important;
}

.red {
  background: #fdf3f5 !important;
}
