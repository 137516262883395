.main {
  display: flex;
  align-items: flex-end;
}
.progressBar {
  .root {
    height: 15;
    border-radius: 5;
  }
  .colorPrimary {
    background-color: "#EEEEEE";
  }
  .bar {
    border-radius: 5;
    background-color: "#1a90ff";
  }
}

.fileInput {
  display: none;
}

.browseButton {
  color: var(--color-prussian-blue);
  margin: 10px 0 0 0;
  border: 2px solid;
  width: 200px;
  &:hover {
    background-color: var(--color-light-yellow);
    color: var(--color-prussian-blue);
  }
}

.fileName {
  font-size: small;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 3px 15px;
  padding: 3px 10px 3px 15px;
  background-color: var(--color-ash);
  border-radius: 50px;
}
.withError {
  padding: 0 10px 10px 10px;
  min-width: 180px;
  margin: 0 0 0 15px;
}

.error {
  position: absolute;
  font-size: 6px;
  top: 20px;
  // left: 5px;
  color: var(--color-salsa);

  font-size: small;
}
.clearIcon {
  margin: 3px 0 0 10px;
  cursor: pointer;
}
