.MuiAutocomplete-option:hover {
  background: var(--color-baby-blue);
  color: var(--color-blue);
  font-weight: 500;
}
.MuiListItem-root.MuiMenuItem-root {
  font-size: 14px;
}

.MuiListItem-root.MuiMenuItem-root:hover {
  background: var(--color-baby-blue);
  color: var(--color-blue);
  font-weight: 500;
}

* {
  font-family: Roboto;
}

.MuiCircularProgress-root {
  color: var(--color-blue);
}

.flex {
  display: flex;
}

.bold {
  font-weight: 500;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.text-right {
  text-align: right;
}

.gray {
  color: var(--color-cement);
}

.italic {
  font-style: italic;
}

.break-all {
  word-break: break-all;
}

.min-50 {
  min-width: 50px;
}

.min-55 {
  min-width: 55px;
}

.min-60 {
  min-width: 60px;
}
.min-70 {
  min-width: 70px;
}

.min-80 {
  min-width: 80px;
}

.min-90 {
  min-width: 90px;
}

.min-100 {
  min-width: 100px;
}

.min-120 {
  min-width: 120px;
}

.min-150 {
  min-width: 150px;
}

.max-60 {
  max-width: 60px;
}

.max-65 {
  max-width: 60px;
}

.max-90 {
  max-width: 90px;
}

.link {
  color: var(--color-mid-blue);
  text-decoration: underline;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.pointer {
  cursor: pointer;
}

.max-70 {
  max-width: 70px;
}

.MuiPaper-elevation8 {
  box-shadow: 1px 2px 2px -3px rgb(0 0 0 / 2%), 0px 2px 11px 1px rgb(0 0 0 / 2%),
    0px 2px 20px 2px rgb(0 0 0 / 4%) !important;
}
