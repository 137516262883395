.container {
  width: 1350px;

  .fieldLabel > p {
    width: 25%;
  }
  .fieldLabel > div {
    width: 35%;
  }
  .fieldPricingLabel > div:first-child {
    width: 12%;
  }
  .fieldPricingLabel > div:last-child {
    width: 87%;
  }
  .continue {
    margin-top: 30px;
    height: 39px;
    padding: 12px 40px;
  }
  .emailAddresses {
    width: 100%;
  }
  .fieldText {
    font-weight: 500;
  }
  .horizontalInput {
    display: flex;
    align-items: center;
    .gasTitle,
    .gasAmount {
      padding-left: 10px;
      width: 100%;
    }
  }
  .fieldPricingLabelPosition {
    margin-left: 164px;
    margin-bottom: -30px !important;
  }
  .pillEmailAddressesContainer {
    display: flex;
    flex-wrap: wrap;
    .emailAddressInPill {
      display: block;
      height: 40px;
      margin-right: 10px;
      margin-bottom: 5px;
      > span {
        padding: 5px 5px 5px 5px;
      }
      .active {
        color: var(--color-grass);
      }
      .pending {
        color: var(--color-cheddar);
      }
      .expired {
        color: var(--color-cement);
      }
      .deactivated {
        color: var(--color-deep-red);
      }
    }
  }
  .italic {
    font-style: italic;
  }
}
.editConfirmModal {
  width: 580px;
}
.onboardDate {
  span {
    font-size: 13px;
  }
}
