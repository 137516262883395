.container {
  width: 1200px;
  .fieldLabel {
    .radioContainer {
      gap: 34%;
    }
    .radioContainerStatus {
      gap: 30.3%;
    }
  }
  .fieldLabel > p {
    width: 22%;
  }
  .fieldLabel > div {
    width: 35%;
  }
  .fieldText {
    color: var(--color-blue-gray);
    font-weight: 500;
    ::first-letter {
      text-transform: uppercase;
    }
  }
  .dateSeparator {
    color: var(--color-gray);
  }
  .dateRange {
    width: 270px;
  }
  .gasAmount {
    width: 120px;
  }
  .continue {
    margin-top: 30px;
    height: 39px;
    padding: 12px 40px;
    font-weight: 500;
  }
}

.longDesc {
  word-break: break-all;
}
