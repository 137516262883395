.checkbox {
  margin-left: -10px !important;
}

:global {
  .MuiSvgIcon-root {
    color: var(--color-prussian-blue);
  }
  .MuiTouchRipple-child {
    background-color: var(--color-prussian-blue);
  }
  .MuiIconButton-root:hover {
    background-color: rgba(19, 115, 209, 0.04) !important;
  }
}
